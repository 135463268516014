/* eslint-disable import/prefer-default-export */
import { useEffect } from "react";

import { useSelector } from "react-redux";

import { CommonErrors } from "@elx-element/common/enums";
import { getConfigurationBoolValue, getConfigurationNumberValue } from "@elx-element/common/envconf";
import { registerGPSTrackingControlListener } from "@elx-element/common/events/listeners";
import { GpsTrackingControl } from "@elx-element/common/events/types";
import { throwSpecificError } from "@elx-element/common/logger";
import {
  gpsTrackingHardDisabledSessionStorageKey,
  storeGpsTrackingHardDisable,
  storeLastGpsLocation,
} from "@elx-element/common/storage";
import { GpsLocationModel } from "@elx-element/common/storage/types";

import { Geolocation, PositionOptions, WatchPositionCallback } from "@capacitor/geolocation";

import { selectPlatformIsActive } from "../store/main/selectors";

const debug = getConfigurationBoolValue(window.env.webcontainer, "ENABLE_DEBUG_LOG");

export const useGpsTrackingControl = (enabled: boolean) => {
  /** Identifikátor instance positionWatcheru */
  let gpsPositionWatcherID: string | undefined;

  const platformActive = useSelector(selectPlatformIsActive);

  /**
   * Inicializace kontinuálního snímání GPS polohy
   */
  const initPositionUpdate = () => {
    // Timeout v milisekundách pro získání další GPS souřadnice
    const timeout = getConfigurationNumberValue(window.env.webcontainer, "GEOLOCATION_TIMEOUT");
    // Prodleva v milisekundách mezi jednotlivými měřeními polohy
    const maximumAge = getConfigurationNumberValue(window.env.webcontainer, "GEOLOCATION_MAXIMUM_AGE");
    // Získává polohu přesně, je to ale časově i bateriově náročná operace
    const enableHighAccuracy = getConfigurationBoolValue(window.env.webcontainer, "GEOLOCATION_HIGH_ACCURACY");

    if (maximumAge >= 0) {
      // nastavení kontinuálního snímání polohy
      const locationOptions: PositionOptions = {
        timeout,
        maximumAge,
        enableHighAccuracy,
      };
      Geolocation.watchPosition(locationOptions, handlePositionUpdate)
        .then(id => {
          gpsPositionWatcherID = id;
          if (debug) {
            console.debug(
              `[Element GPS]: GPS continual watching ${gpsPositionWatcherID} started with configuration : ${JSON.stringify(
                locationOptions
              )}`
            );
          }
        })
        .catch(err => {
          throwSpecificError(
            CommonErrors.gpsPositionUnavailable,
            "[Element GPS]: GPS continual watch initialization failed.",
            err
          );
        });
    }
  };

  /**
   * Zastavení kontinuálního snímání polohy
   */
  const clearPositionUpdate = () => {
    if (gpsPositionWatcherID) {
      if (debug) {
        console.debug(`[Element GPS]: GPS continual watch stop: ${gpsPositionWatcherID}`);
      }
      Geolocation.clearWatch({ id: gpsPositionWatcherID });
    }
  };

  /**
   * zpracování nové GPS polohy
   * @param position - instance GeolocationPosition
   * @param err - případná chyba
   */
  const handlePositionUpdate: WatchPositionCallback = (position, err) => {
    if (!err && position) {
      // transformace objektu
      const gpsLocation: GpsLocationModel = {
        position: `${position.coords.latitude}, ${position.coords.longitude}`,
        accuracy: position.coords.accuracy.toString(),
        timestamp: Date.now().toString(),
      };
      storeLastGpsLocation(gpsLocation);
    } else {
      if (debug) {
        console.debug("[Element GPS]: GPS handlePositionUpdate", err);
        console.debug("[Element GPS]: GPS, error message is =>", err.message);
      }

      // celá chybová zpráva v capacitoru 3 je nově "Location permission was denied"
      // pokud je v err.message objekt, tak aplikace padala na "e.message.includes is not a function"
      if (err.message && !(err.message instanceof Object) && err.message.includes("denied")) {
        // pokud nemám uživatelské povolení po první chybě tohoto typu zastavím snímání
        clearPositionUpdate();
        gpsPositionWatcherID = "denied";
      }
    }
  };

  /**
   * Ovládání snímání Gps trackování.
   * @param detail
   */
  const handleGpsTrackingColntrolChange = (detail?: GpsTrackingControl | undefined) => {
    if (detail) {
      if (detail.disabled) {
        clearPositionUpdate();
        storeGpsTrackingHardDisable(true);
      } else {
        initPositionUpdate();
        storeGpsTrackingHardDisable(false);
      }
    }
  };

  useEffect(() => {
    if (enabled) {
      registerGPSTrackingControlListener(e => handleGpsTrackingColntrolChange(e.detail));
    }
  }, [enabled]);

  useEffect(() => {
    if (platformActive !== undefined && enabled) {
      if (
        platformActive &&
        gpsPositionWatcherID !== "denied" &&
        sessionStorage.getItem(gpsTrackingHardDisabledSessionStorageKey) ===
          null /* neexistuje blokace gps lokalizace */
      ) {
        initPositionUpdate();
      } else {
        clearPositionUpdate();
      }
    }
  }, [platformActive, enabled]);
};
